import React, { useState, useEffect } from "react";
import CircularLoader from "./circularprogresbar";
import axios from "axios";
//const BASE_URL = "http://localhost:3001";

const BASE_URL = 'https://stagingapi.lifti.io';
function Swich() {
  const [Payload, setPayload] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [TransactionAmount, setTransactionAmount] = useState(0);
  const [userId, setuserId] = useState("");
  const [tokenName, settokenName] = useState(0);
  const [tokenValue, settokenValue] = useState(0);
  const [tokenPriceInUsdt, settokenPriceInUsdt] = useState(0);
  const [currency, setcurrency] = useState('');
  const [token, settoken] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _TransactionAmount = urlParams.get("TransactionAmount");
    const _userId = urlParams.get("userId");
    const _tokenName = urlParams.get("tokenName");
    const _tokenValue = urlParams.get("tokenValue");
    const _tokenPriceInUsdt = urlParams.get("tokenPriceInUsdt");
    const _token = urlParams.get("token");
    const _currency= urlParams.get("currency");
    setcurrency(_currency);
    setTransactionAmount(_TransactionAmount);
    setuserId(_userId);
    settokenName(_tokenName);
    settokenValue(_tokenValue);
    settokenPriceInUsdt(_tokenPriceInUsdt);
    settoken(_token);
  }, []);

  const getauthToken = () => {
    setIsLoading(true)
    let data = {
      userId: userId,
      tokenName: tokenName,
      tokenValue: tokenValue,
      TransactionAmount: TransactionAmount,
      tokenPriceInUsdt: tokenPriceInUsdt,
      currency: currency,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/swich/getTransactionUrl`,
      headers: {
        accept: "*/*",
        Authorization:token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false)
        setPayload(response.data);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const handleSubmitPageRedirection = (e) => {
    e.preventDefault();
    setIsLoading(true);
    document.getElementById("PageRedirectionForm").submit();
  };

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        {!Payload && (
          <div className="col-md-6 py-5">
            <h3>Payable Amount</h3>
            <input
              className="form-control"
              autoComplete="off"
              disabled
              type="text"
              value={TransactionAmount}
            />
            <br />
            <button
              onClick={() => getauthToken()}
              className="btn btn-danger w-100"
              id="handshake"
            >
              {isloading ? <CircularLoader /> : "Proceed"}
            </button>
          </div>
        )}
        {Payload && (
          <div className="row justify-content-md-center py-5">
            <div className="col-md-6">
              <h3>Lifti Token Payment</h3>
              <form
                action="https://sandbox-payin-pwa.swichnow.com"
                id="PageRedirectionForm"
                method="get"
                noValidate="novalidate"
                onSubmit={handleSubmitPageRedirection}
              >
                <input
                  className="form-control my-2"
                  id="amount"
                  name="amount"
                  value={Payload?.amount}
                  readOnly="readOnly"
                />
                
                <input
                  className="form-control my-2"
                  id="billReferenceNo"
                  name="billReferenceNo"
                  type="hidden"
                  readOnly="readOnly"
                  value={Payload?.billReferenceNo}
                  hidden="true"
                />
                <input
                  className="form-control my-2"
                  id="channel"
                  name="channel"
                  readOnly="readOnly"
                  value={Payload?.channel}
                  hidden="true"
                />
                <input
                  className="form-control my-2"
                  id="checksum"
                  name="checksum"
                  readOnly="readOnly"
                  value={Payload?.checksum}
                  hidden="true"
                />
                <input
                  className="form-control my-2"
                  id="clientid"
                  name="clientid"
                  readOnly="readOnly"
                  value={Payload?.clientid}
                  hidden="true"
                />
                <input
                  className="form-control my-2"
                  id="currency"
                  name="currency"
                  readOnly="readOnly"
                  value={Payload?.currency}
                />
                <input
                  className="form-control my-2"
                  id="customertransactionid"
                  name="customertransactionid"
                  readOnly="readOnly"
                  value={Payload?.customertransactionid}
                />
                <input
                  className="form-control my-2"
                  id="description"
                  name="description"
                  readOnly="readOnly"
                  value={Payload?.description}
                />
                <input
                  className="form-control my-2"
                  id="email"
                  name="email"
                  readOnly="readOnly"
                  value={Payload?.email}
                />
                <input
                  className="form-control my-2"
                  id="item"
                  name="item"
                  readOnly="readOnly"
                  value={Payload?.item}
                />
                <input
                  className="form-control my-2"
                  id="msisdn"
                  name="msisdn"
                  readOnly="readOnly"
                  value={Payload?.msisdn}
                />

                <input
                  className="form-control my-2"
                  autoComplete="off"
                  id="payeename"
                  name="payeename"
                  placeholder="Order ID"
                  type="text"
                  readOnly="true"
                  value={Payload?.payeename}
                />

                <br />
                <button type="submit" className="btn btn-danger w-100" id="run">
                  {isloading ? <CircularLoader /> : "Proceed to Transaction"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Swich;
