import React,{useState} from 'react';
import axios from 'axios';
const BASE_URL = "http://localhost:3001";
function TransactionResult  ()  {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateTransaction = () => {
    setIsUpdating(true);
    // Make API call to update transaction
    axios.post(`${BASE_URL}/buy/updateTransaction`, { orderId })
      .then(response => {
        // Handle success response
        console.log('Transaction updated successfully:', response.data);
        // Perform any necessary actions, such as updating state or showing a success message
      })
      .catch(error => {
        // Handle error response
        console.error('Error updating transaction:', error);
        // Perform any necessary actions, such as showing an error message
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  // Extracting query parameters from URL
  const params = new URLSearchParams(window.location.search);
  const resultCode = params.get('RC');
    const TS = params.get('TS');
    const orderId = params.get('O');
  // Check the result code and render appropriate content
  let content;
  if (resultCode === '01' && TS==='F') {
    content = (
      <div className="container">
        <div className="alert alert-danger mt-5" role="alert">
          <h4 className="alert-heading">Transaction Failed</h4>
          <h5 className="alert-heading">Order Id : {orderId}</h5>
          <p>Sorry, your transaction failed.</p>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="container">
        <div className="alert alert-success mt-5" role="alert">
          <h4 className="alert-heading">Transaction Success</h4>
          <h5 className="alert-heading">Order Id : {orderId}</h5>
          <p>Your transaction was successful!</p>
        </div>

        <button
          className="btn btn-success w-100"
          onClick={handleUpdateTransaction}
          disabled={isUpdating} // Disable button during API call
        >
          {isUpdating ? 'Updating...' : 'Initiate Token Transfer!!'}
        </button>
      </div>
    );
  }

  return <div>{content}</div>;
};

export default TransactionResult;
